.stats-widget {
    display: block;
    background: @gray-lighter;
    padding: 20px;
    width: 33%;

    &__title {
        text-align: center;
        font-weight: bolder;
        font-size: 2em;
    }

    &__content {
        padding-top: 35px;
        text-align: center;
        font-size: 3em;
    }
}