.ui-autocomplete{
	background-color: @white;
    border: 1px solid @gray-light;
    border-top: 0;
    padding:0; 

    .ui-menu-item:last-child{
        .ui-menu-item-wrapper{
            border-bottom: 0;
        }
    }

    .ui-menu-item-wrapper{
        font-size: @fs1;
        color: @black;
        height: @input-height;
        padding: @x05 @x1;
        display: flex;
        align-items: center;
        border-bottom: 1px solid @gray-light;

        &.ui-state-active{
            margin: 0;
            color: @white;
            background-color: @color-primary;
            .strong;
        }

    }
}