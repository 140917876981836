// Shockworks mixins
// =============================================================================

.block {display: block;}
.inline-block {display: inline-block;}
.inline {display: inline;}

.size (@width: 20px, @height: 20px) {
        width: @width;
        height: @height;
        display: block;
}

.squere (@size: 0px){
        .size(@size, @size);
}

.l-height (@height: 20px) {
        height: @height;
        line-height: @height;
}

.f-s (@f: 12px){font-size: @f;}

.m(@x){
        margin:         @x;
}
.m-t(@x){
        margin-top:     @x;
}
.m-b(@x){
        margin-bottom:  @x;
}
.m-l(@x){
        margin-left:    @x;
}
.m-r(@x){
        margin-right:   @x;
}
.m-tb(@x){
        margin-bottom:  @x;
        margin-top:     @x;
}
.m-bt(@x){
        margin-bottom:  @x;
        margin-top:     @x;
}
.m-lr(@x){
        margin-right:   @x;
        margin-left:    @x;
}
.m-rl(@x){
        margin-right:   @x;
        margin-left:    @x;
}

.p(@x){
        padding:        @x;
}
.p-t(@x){
        padding-top:    @x;
}
.p-b(@x){
        padding-bottom: @x;
}
.p-l(@x){
        padding-left:   @x;
}
.p-r(@x){
        padding-right:  @x;
}
.p-bt(@x){
        padding-bottom: @x;
        padding-top:    @x;
}
.p-tb(@x){
        padding-bottom: @x;
        padding-top:    @x;
}
.p-lr(@x){
        padding-right:  @x;
        padding-left:   @x;
}
.p-rl(@x){
        padding-right:  @x;
        padding-left:   @x;
}

.relative               {position: relative;}
.absolute               {position: absolute;}
.fixed                  {position: fixed;}
.static                 {position: static;}

.left                   {float: left;}
.right                  {float: right;}
.center                 {text-align: center;}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.flex-parent {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
}

.flex-child {
    flex: 1 1 auto;
}

.flex-child--fallback {
    .inline-block; .left;
    flex: 1 1 auto;
}

// Mixiny
// -----------------------------------------------------------------------------

.clearfix {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// WebKit-style focus
.tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.transition {
    transition: all .150s linear;
}

// Transition
// ==============================================================================
.trans(@transition-style, @transition-time, @transition-type) {
	transition: @transition-style @transition-time @transition-type;
}