/* ICONFONT */
@large-icon-size:          25px;

@font-face {
    font-family: 'icon';
    src: url('fonts/iconfont/icon.eot');
    src: url('fonts/iconfont/icon.eot?#iefix') format('eot'),
        url('fonts/iconfont/icon.woff') format('woff'),
        url('fonts/iconfont/icon.ttf') format('truetype'),
        url('fonts/iconfont/icon.svg#icon') format('svg');

    font-weight: normal;
    font-style: normal;
}

.icon {
    .inline-block; .left;

    font-family: icon;
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    line-height: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	@media @max-sm {
		/*font-size:2rem;*/
	}
}

/* USE ICON ON PSEUDO ELEMENTS */
.icon-pseudo (@icon-name, @font-size: inherit, @line-height: inherit) {
    content: @icon-name;
    .inline-block; .left;

    font-family: 'icon';
    font-size: @font-size;
    line-height: @line-height;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* TYPES */
.icon {
    .p-l(0); .p-r(7px);
}

// just in case
.icon--before {
    .left;
    .p-l(0); .p-r(7px);

    &.icon--reverse {
        .p-l(7px); .p-r(0);
    }
}

.icon--large {
    .f-s(@large-icon-size);
}

.icon--small {
    font-size: @fs05;
}

.icon--after {
    .right;
    .p-l(7px); .p-r(0);

    // override
    &.icon--before {
        .icon--before;
    }
}

.icon--reverse {
    .p-l(7px); .p-r(0);
    transform: rotate(180deg);
}

.icon--reverse.icon--after {
    .p-l(0); .p-r(7px);
}

.icon--alone {
    .p(0);
}

/* COLORS */
.icon--color {
    color: @color;
}

.icon--primary {
    color: @color-primary;
}

.icon--secondary {
    color: @color-secondary;
}
