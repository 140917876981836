.add-voucher{
	background-color: @white;
	box-shadow: 0 3px 6px fade(@black, 16%);
	padding: @x3 @x4;
	margin-bottom: @x2;
	text-align: center;
	min-height: 335px;
	position: relative;

	.full_link{
		z-index: 2;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	img{
		margin: @x3 0 @x3 0;
	}

	&__wrap{
		margin-top: @x2*2;
	}
}