.ui-datepicker{
	background-color: @white;
	border: 1px solid @gray-light;
	font-size: @fs2;
	box-shadow: 0 3px 6px fade(@black, 16%);

	td{
		span, a{
			border: 1px solid @gray-light;
			color: @color;
		}
	}

	.ui-state-highlight{
		color: @white;
		background-color: @color-secondary;
		border-color: @color-secondary;
	}

	.ui-state-active{
		color: @white;
		background-color: @color-primary;
		border-color: @color-primary;
	}

	.ui-datepicker-prev{
		left: 0;
		top: 7px;
		text-align: center;
		color: @color;

		&:before{
			content: @icon-arrow_small;
			.icon;
			transform: rotate(180deg);
			padding: 0;
			float: none;
		}

		span{
			display: none;
		}
	}

	.ui-datepicker-next{
		right: 0;
		top: 7px;
		text-align: center;
		color: @color;

		&:before{
			content: @icon-arrow_small;
			.icon;
			padding: 0;
			float: none;
		}

		span{
			display: none;
		}
	}
}