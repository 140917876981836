// Component: Formuláře
// =============================================================================

// Nastavení
// -----------------------------------------------------------------------------

// Výška inputů i ikon, checkboxu, radií, všeho
@input-height: 40px;
@checkbox-height: 24px;
@radio-height: 16px;

// Border u všech inputů
@input-border: 1px solid @gray;
@input-focus-color: @color-primary;
@input-border-radius: 5px;

@input-background: #fff;
@input-color: @color;
@input-box-shadow: none;

// Barva, pokud je vyplneno v poradku
@input-color-pass: #00A86B;
// Barva, pokud je vyplneno a je neco v neporadku
@input-color-error: #C21807;



// Třídy a mixiny pro jednotlivé elementy
// -----------------------------------------------------------------------------

// Třídá pro nové formy, samostatná není nikde použita
.form {

}

.form__box{
    background-color: @gray-lighter;
    border: 1px solid @gray-light;
    padding: @x3 @x1 @x1 @x1;
    margin-bottom: @x3;
    margin-left: -@x1;
    margin-right: -@x1;

    @media @min-sm{
        margin-left: auto;
        margin-right: auto;
    }
}

// Řádek, nahrazuje klasickou grid row
.form__row {
    position: relative;
    margin-bottom: 1rem;
    //margin-left: -@grid-gutter-width/2;
    //margin-right: -@grid-gutter-width/2;
    .clearfix;

    .form__radio:last-child{
        margin-right: 0;
    }
}

// Jednořádkový formulář - textový input je těsně vedle submitovacího tlačítka bez mezery
.form__inline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

// Použitelná legenda - nadpis pro jednotlivé sekce formuláře
.form__legend {
    //background: @gray-lighter;
    color: @gray-darker;
    font-size: @fs2;
    .stronger;
    width: 100%;
    margin: 0 0 @x1 0;
    //margin-bottom: 1rem;
}

// Zarovnaný label pomocí flexu, z důvodu budoucího přidání ikon apod..
.form__label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: @input-height;

    @media @min-md {
        justify-content: flex-end;
    }
}

.label {
    font-size: @fs05;
    .strong;
    cursor: pointer;
    color: @color;
    padding: 0;

    &.required {
        &:after {
            content: ' *';
            color: @color-error;
        }
    }
}

//chybové hlášky
.form__error-message{
    color: @color-error;
    margin-top: @x05/2;
    margin-bottom: @x05/2;
    line-height: 1.3;
    display: block;
    font-size: @fs05;
}

// Textový input
.form__input {

    // Ovlivňuje všechny vnořené inputy
    input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
        .transition;
        position: relative;
        color: @input-color;
        font-size: @fs05;
        width: 100%;
        height: @input-height;
        line-height: 1;
        padding-left: @x1;
        padding-right: @x1;
        background: @input-background;
        border-radius: @input-border-radius;
        border: @input-border;
        // box-shadow: @input-box-shadow;
        vertical-align: middle;
        z-index: 2;

        &::-webkit-input-placeholder {
            transition: color .2s linear;
            color: @gray;
        }

        &:focus {
            border-color: @input-focus-color;
            outline: none;
            box-shadow: @input-box-shadow;
        }

        &:disabled{
            opacity: 0.5;
            background-color: @gray-lighter;
        }

        &:required{
            border-color: @black;
        }


    }

    &--error{

        position: relative;

        &:after{
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: @color-error;
            content: @icon-close_small;
            .icon;
            color: @white;
            font-size: 0.615rem;
            padding: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 2.4rem;
            right: @x1;
            z-index: 2;
        }


        input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
            border: 2px solid @color-error;

            &:required{
                border-color: @color-error;
            }
        }

        .form__input__date:after{
            display: none;
        }

        .form__input__search:after{
            display: none;
        }
    }

    // Pro první input v form__inline
    &--left {
        // Flex: lze zvětšit, lze zmenšit
        flex: 1 1 auto;
        position: relative;
        .form__error-message{
            position: absolute;
            bottom: -@x3;
            width: 100%;
            right: 0;

            @media @min-lg{
                left:0;
                top: -@x3;
                bottom: auto;
                right: auto;
            }
        }

        input[type="text"], input[type="email"], input[type="password"], input[type="number"]{
            // Reset zaoblení rohů
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0;

            // Při focusu je pravý border moc výrazný a narušuje tlačítko umístěné vpravo, proto přidáme 50% průhlednost
            &:focus {
                border-right-color: fadeout(@input-focus-color, 50%);
            }
        }
    }

    // Pro druhý input v form__inline
    &--right {
         // Flex: nelze zvětšit, lze zmenšit
        flex: 0 1 auto;

        .btn {
            // resetování input[type="submit"]
            height: @input-height;
            line-height: @input-height - 2px; // 2x border
            // Přidání
             // box-shadow: @input-box-shadow;
            // Reset zaoblení rohů
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            // Reset prohlížečů
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // Třída, která zajistí změnu pro vyjížděcí label
    &--floatlabel {
        position: relative;

        @media @max-sm {
            // Místo pro vyjetí labelu nahoru
            margin-top: 2rem;
        }

        &:before {
            .transition;
            content: attr(data-floatlabel);
            position: absolute;
            top: 0;
            bottom: 0;
            left: @x1; // wrap padding
            color: @input-color;
            font-size: @fs05;
            /*width: 100px;*/
            height: 1rem;
            line-height: 1;
            margin: auto;
            text-align: right;
            z-index: 1;

            @media @max-sm {
                width: auto;
            }
        }

        // VIA JS - přehazování stavu viditelný / neviditelný
        &.is-active {
            &:before {
                left: 0px;
                /*text-align: right;*/

                @media @min-md {
                    padding-right: @x1;
                    transform: translateX(-100%);
                }

                @media @max-sm {
                    /*left: @x1;*/
                    /*top: -4rem;*/
                    text-align: left;
                    @ahoj: ~'calc( (@{input-height} - @{fs05} + 4px) * -1) ';
                    transform: translateY(@ahoj);
                }
            }
        }

        // VIA JS - přehazování stavu focused podle inputu
        &.is-focused {
            &:before {
                color: @color-secondary;
            }
        }
    }

    // Pokud se přiřadí třídě form__input, zobrazí se, že je v pořádku
    &.pass {
        input[type="text"], input[type="email"], input[type="password"] {
            border-color: @input-color-pass;
        }

        &:after {
            content: "\E00F";
            font-family: icon;
            font-size: @fs3;
            .strong;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 1.5rem;
            margin: auto;
            height: 1.5rem;
            color: @input-color-pass;
            z-index: 3;
        }
    }

    // Pokud se přiřadí třídě form__input, zobrazí se, že je něco v nepořádku
    &.error {
        input[type="text"], input[type="email"], input[type="password"] {
            border-color: @input-color-error;
        }

        &:after {
            content: "\E014";
            font-family: icon;
            font-size: @fs3;
            .strong;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 1.5rem;
            margin: auto;
            height: 1.5rem;
            color: @input-color-error;
            z-index: 3;
        }
    }

    &__search{
        position: relative;

        &:after{
            .icon;
            content: @icon-search;
            position: absolute;
            right: 0;
            top: 1px;
            z-index: 2;
            font-size: @fs2;
            bottom: 0;
            margin: auto;
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: @gray;
            right: @x05;
            background: #fff;
            height: ~"calc(100% - 3px)";
        }
    }

    &__date{
        position: relative;

        &:after{
            .icon;
            content: @icon-date;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            font-size: @fs2;
            bottom: 0;
            margin: auto;
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: @gray;
            right: @x1;
        }
    }

    &__file{
        position: relative;

        &:after{
            .icon;
            content: @icon-file;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            font-size: @fs2;
            bottom: 0;
            margin: auto;
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: @gray;
            right: @x1;
        }
    }
}

// Textarea
.form__textarea {
    .transition;
    resize: vertical;
    position: relative;
    color: @input-color;
    font-size: @fs05;
    width: 100%;
    line-height: 1.5;
    padding: @x05 @x1;
    background: @input-background;
    border-radius: @input-border-radius;
    border: @input-border;
    // box-shadow: @input-box-shadow;
    vertical-align: middle;
    z-index: 2;

    &::-webkit-input-placeholder {
        transition: color .2s linear;
        color: @gray;
    }

    &:focus {
        border-color: @input-focus-color;
        outline: none;
    }

    // Různé přednastavené velikosti
    &.size1 {
        min-height: @input-height;
    }

    &.size2 {
        min-height: @input-height * 2;
    }

    &.size3 {
        min-height: @input-height * 3;
    }

    &.size4 {
        min-height: @input-height * 4;
    }

    &.size5 {
        min-height: @input-height * 5;
    }

    //počítání znaků
    &__chars{
        float: right;
    }
}


// Přestylovaný základní select
.form__select {
    .transition;
    position: relative;
    color: @input-color;
    font-size: @fs05;
    width: 100%;
    height: @input-height;
    line-height: 1;
    padding-left: @x05;
    padding-right: @x05;
    background: @input-background;
    border-radius: @input-border-radius;
    border: @input-border;
    // box-shadow: @input-box-shadow;
    vertical-align: middle;
    cursor: pointer;
    z-index: 2;
    -webkit-appearance: none;
    outline: 0;

    &:focus {
        border-color: @input-focus-color;
        box-shadow: @input-box-shadow;

        option {
            /*color: @gray-dark;*/
        }
    }
}

// Container na select pro přidání vlastní šipky
.select-box{
    position: relative;

    // Vlastní šipka do selectu
    /*&:after {
        content: @icon-select_arrow;
        .icon;
        position: absolute;
        top: 0;
        right: 0;
        height: @input-height;
        width: @input-height;
        line-height: @input-height;
        padding-right: 0;
        font-size: 1rem;
        color: @black;
        .center;
        z-index: 2;
        pointer-events: none;
        font-size: 0.4rem;
    }*/

    .ui-selectmenu-button{
        .form__select;
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: @x05 @x1;
        border-color: @gray-light;

        &:focus{
            border-color: @gray-light;
        }

        &:after{
            content: @icon-select_arrow;
            .icon;
            position: absolute;
            top: 0;
            right: 0;
            height: @input-height;
            width: @input-height;
            line-height: @input-height;
            padding-right: 0;
            font-size: 1rem;
            color: @black;
            .center;
            z-index: 2;
            pointer-events: none;
            font-size: 0.4rem;
        }

        .ui-selectmenu-text{
            font-size: @fs1;
            .strong;
            color: @color-primary;
            overflow: visible;
        }
    }
}

//ui select menu samotný dropdown
.ui-selectmenu-menu{

    max-height: 250px;
    overflow: auto;
    
    .ui-menu{
        background-color: @white;
        border: 1px solid @gray-light;
        border-top: 0;
        padding:0; 

        .ui-menu-item:last-child{
            .ui-menu-item-wrapper{
                border-bottom: 0;
            }
        }

        .ui-menu-item-wrapper{
            font-size: @fs1;
            color: @black;
            height: @input-height;
            padding: 10px @x1;
            display: block;
            align-items: center;
            border-bottom: 1px solid @gray-light;

            &.ui-state-active{
                margin: 0;
                color: @white;
                background-color: @color-primary;
                .strong;
            }

        }
    }
}

// Nový checkbox, doporučuji přiřadit nadřazené form__row nějakou height, pro lepší kontrolu
.form__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    float: left;
    line-height: @input-height;
    min-height: @input-height;
    vertical-align: middle;
    font-size: @fs1;
    //margin-bottom: 1rem;
    cursor: pointer;
    //padding-right: @x1;
    //padding-left: @x1;
    flex-wrap: wrap;

    input[type="checkbox"] {
        -webkit-appearance: none; // Vypnutí základního stylu
        position: absolute;
        opacity: 0;

        &:focus {
            outline: none;
        }
    }

    @media @min-md {
        width: 100%;
    }

    @media @max-sm {
        width: 100%;
        padding: 0;
        text-align: left;
    }

    &.required {
        &:after {
            content: ' *';
            color: @color-error;
        }
    }

    &__icon {
        .transition;
        flex: 0 0 auto;
        float: left;
        position: relative;
        border: 1px solid @black;
        border-radius: 2px;
        width: @checkbox-height;
        height: @checkbox-height;
        transform: rotate(0deg);
        margin-top: 0;

        &:before {
            .transition;
            content: @icon-check;
            .icon;
            font-size: @fs05;
            color: @white;
            position: absolute;
            opacity: 0;
            padding: 0;
            left: 0;
            right: 0;
            .center;
            line-height: @checkbox-height;
        }

    }

    &__text {
        float: left;
        padding-left: @x05;
        padding-right: @x05;
        vertical-align: middle;
        text-align: left;  // rewrite old forms
        line-height: 1.5;
        flex: 1 0;
        font-size: @fs1;
        .normal;
        .transition;
        @media @max-sm {
            width: 90%;
        }
    }

    input:checked + &__icon {
        position: relative;
        width: @checkbox-height;
        height: @checkbox-height;
        background-color: @color-primary;
        border-color: @color-primary;

        &:before {
            opacity: 1;    
        }
    }

    input:checked + &__icon + &__text{
        color: @black;
    }

    input:disabled + &__icon{
        border-color: @gray;
    }

    input:disabled + &__icon + &__text{
        color: @gray;
    }

    a{
        color: @color;
        text-decoration: underline;
    }
}

// Nové radio, v podstatě platí to samé, co pro checkbox
.form__radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    float: left;
    line-height: @input-height;
    min-height: @input-height;
    vertical-align: middle;
    font-size: @fs1;
    //margin-bottom: 1rem;
    cursor: pointer;
    //padding-right: @x1;
    //padding-left: @x1;
    padding: @x05;
    margin-right: @x05;
    border: 1px solid @gray;
    border-radius: 3px;
    background-color: @white;

    &.checked{
        border-color: @color-primary;
        background-color: fade(@color-primary, 10%);
    }

    input[type="radio"] {
        -webkit-appearance: none; // Vypnutí základního stylu
        position: absolute;
        opacity: 0;
    }

    @media @min-md {
        width: auto;
        /*max-height: @input-height / 2;*/
    }

    @media @max-sm {
        width: 100%;
        //padding: 0;
        text-align: left;
    }

    &.required {
        &:after {
            content: ' *';
            color: @color-error;
        }
    }

    &__icon {
        .transition;
        flex: 0 0 auto;
        float: left;
        position: relative;
        width: @radio-height;
        height: @radio-height;
        border: 1px solid @gray-light;
        border-radius: 50%;
        background-color: @gray-lighter;

        &:after {
            .transition;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            background: @gray-lighter;
            width: @radio-height / 4;
            height: @radio-height / 4;
            border-radius: 50%;
            transform: scale(0);
        }
    }

    &__text {
        // float: left; // VYPNUTO - dělá bordel na LG/Chrome (viz Browserstack)
        padding-left: @x1;
        padding-right: @x1;
        vertical-align: middle;
        line-height: 1.5;
        @media @max-sm {
            width: 90%;
        }
    }

    input:checked + &__icon {
        border-color: @color-primary;
        background-color: @color-primary;

        &:after {
            background: @white;
            transform: scale(1);
        }
    }

    input:checked + &__icon + &__text{
        color: @color-primary;
    }
}

//jquery dialog box pro zasilkovnu
.uiz-dialog{
    padding: 0;
    font-family: @fontfamily-default !important;
    border: 0;

    .uiz-dialog-titlebar{
        background: @gray-lighter;
        border: 0;
        border-radius: 0;

    }

    .uiz-widget-header{
        color: @color;
    }

    .uiz-dialog-titlebar-close{
        padding: 0;
        color: @gray;
    }

    .uiz-dialog-titlebar-close.uiz-state-hover{
        background: transparent;
        border: 0;
        color: @color;
    }

    .uiz-dialog-titlebar-close span{
        background: transparent;
        text-indent: 0;
        margin: 0;
        width: 20px;
        height: 20px;

        &:before{
            .icon-pseudo(@icon-cross);
            .icon;
            font-size: @fs3;
        }
    }

    .uiz-dialog-buttonpane{
        display: none;
    }
}

.uiz-widget-overlay{
    background: rgba(0, 0, 0, 0.8);
    opacity: 1;
}

.packetery-info-dialog{
    font-family: @fontfamily-default !important;
}

//simplebar 
.simplebar-scrollbar:before{
    background-color: @gray-light;
}

.simplebar-track:hover .simplebar-scrollbar:before,
.simplebar-track .simplebar-scrollbar.visible:before{
    opacity: 1;
}

.form__product{
    &__name{
        color: @color;
        //font-size: @fs05;
    }

    &__value{
        font-size: 1.5rem;
        color: @color;
        margin-top: @x05/2;
    }
}

.discount-price{
    color: @gray;
    font-size: @fs1;
    margin-top: @x3/1.5;
}

.form__nahled{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @gray-lighter;
    padding: @x2 @x3;
    color: @gray-darker;
    text-transform: uppercase;
    font-size: @fs05;
    .strong;
    flex-direction: column;

    @media @min-md{
        flex-direction: row;
    }

    a{
        color: @gray-darker;
        text-decoration: none;
        padding: 15px;
        color: @color-primary;
        font-size: 14px;
    }

    a:first-child{
        margin-bottom: @x2;
        @media @min-md{
            margin-bottom: 0;
        }
    }

    .icon{
        font-size: @fs2;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.form__counter{

    display: flex;
    align-items: center;
    margin-top: @x3/1.5;
    margin-bottom: @x3/1.5;

    @media @min-md{
        margin-bottom: 0;
    }

    .plus, .minus{
        width: @input-height;
        height: @input-height;
        border-radius: 50%;
        background-color: @color-primary;
        display: block;
        margin-right: @x1;
        position: relative;
        box-shadow: 0 3px 6px fade(@black, 16%);

        &.disabled{
            background-color: @gray-light;
            box-shadow: none;
        }

        &:after{
            content: "";
            position: absolute;
            width: 2px;
            height: 15px;
            background-color: @white;
            transform: rotate(90deg);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }

    .plus{
        &:before{
            content: "";
            position: absolute;
            width: 2px;
            height: 15px;
            background-color: @white;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

//vybrané položky
.selected_area{
    padding: @x1;
    background-color: fade(@color-primary, 5%);

    .label{
        color: @color-primary;
        //font-size: 1.1rem;
        //font-weight: normal;
        display: inline-block;
    }
}

.success-send{

    &__message{
        font-size: @fs2;
        text-align: center;

        .icon{
            font-size: 2.46rem;
            float: none;
            padding: 0;
        }
    }
}

//buttony dát pod sebe
.row .col-md-6 .btn{
    margin-bottom: @x1;

    @media @min-md{
        margin-bottom: 0;
    }
}

#custom_autocomplete_wrap {
    
}

.custom_autocomplete_group{
    /*width: 100%;

    margin-left: auto;
    margin-right: auto;

    @media @min-xs{
        width: 50%;
    }

    @media @min-sm{
        width: 33%;
    }*/
}

.popup-header {
    position: fixed;
}

.popup-content {
    padding-top: 190px;
}

.popup-anchors {
    margin-top: 4px;

    .btn {
        margin-bottom: @x05;
        margin-right: @x05;
    }
}

#changeRoleForm #ui-id-1-button {
    width: 150px;
}