.login_form{
	border-top: 4px solid @color-primary;
	background-color: @white;
	box-shadow: 0 3px 30px fade(@black, 16%);
	text-align: center;
	padding: @x2*2 0 @x3*2 0;

	h1{
		color: @gray-dark;
		.normal;
		font-size: @fs3;
		margin: @x2*2 0 @x2 0;
	}

	.form__input{
		text-align: left;
	}

	.btn{
		max-width: 220px;
	}

	&__wrap{
		height:100%;
	}
}

html.login_page{
	height: 100%;

	body{
		height: 100%;
		display: flex;
    align-items: center;
	}

	.container-fluid{
		width: 100%;
	}
}