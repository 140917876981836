.voucher-steps{
	margin-bottom: @x4;
	margin-top: @x3;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	max-width: 1220px;
	margin-left: auto;
	margin-right: auto;

	@media @min-md{
		flex-direction: row;
	}

	.step{
		border-top: 3px solid transparent;
		.strong;
		text-transform: uppercase;
		padding: @x05 @x1;
		display: block;
		text-align: center;
		text-decoration: none; 
		color: @color;

		&.disabled{
			color: @gray;
		}

		&.active{
			border-color: @color-primary;
			color: @color-primary;
		}
	}
}