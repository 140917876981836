//  Popups
//  ============================================================================

.popup {
    transition: all .3s ease-in-out;
    visibility: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 90vh;
    overflow: auto;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    background-color: transparent;
    color: @white;
    padding: @x3;
    border-radius: 3px;
    z-index: 101;
    -webkit-font-smoothing: antialiased;

    @media @max-sm {
        width: 90%;
    }

    &.active {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    &__cross {
        transition: color .2s linear;
        position: absolute;
        top: 0;
        right: 0;
        width: @input-height;
        height: @input-height;
        padding:0;
        margin: @x05;
        border: 0;
        font-size: @fs05;
        color: @color-primary;
        background: transparent;
        cursor: pointer;
        border: 1px solid @color-primary;
        border-radius: @btn-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;

        @media @min-lg{
            font-size: @fs3;
        }

        &:hover {
            color: @color-primary;
            text-decoration: none;
        }
        
        &:before {
            .icon-pseudo(@icon-cross);
        }
    }

    // samostatný element kvůli kolizi s product-gallery
    &__darken-page {
        .darken-page;
    }

    .main_heading{
        margin-top: 0;
    }
}

// Bílé pozadí
.popup--white {
    background-color: @white;
    color: @black;

    .popup__cross {
        color: @color-primary;
        &:hover {
            color: @color-primary;
        }
    }
}

.popup--fullwidth{
    width: 100%;
    height: 100%;
    transform: translate(0%,0%) scale(1) !important;
    left: 0;
    top: 0;
    max-height: 100%;
}

/*body.popup-active {
    overflow: hidden;
}*/

// Posune box nahoru (zruší centrování - vhodné pro malé boxy)
.popup--small {
    top: 33%;

    &.active {
        transform: translate(-50%, -33%) scale(1);
    }
}

.darken-page {
    transition: all .15s linear;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0,0,0,0);
    width: 100%;
    height: 100vh;
    z-index: 100;

    &.active {
        visibility: visible;
        background-color: rgba(0,0,0,.8);
    }
}



html.popup-active {
    overflow: hidden;
}

.services-popup {
    display: none;
}

.services-popup--active {
    display: block;

    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;

    background: white;

    .services-popup-header {
        height: 100px;
        overflow: hidden;
        padding-top: @x05;
        background: #f6f6f6;

        .container-fluid{
            @media @min-md{
                display: flex;
                align-items: center;
                height: 100px;
            }

            & > .row{
                @media @min-md{
                    flex: 1;
                }
            }
        }

        .popup__cross{
            @media (min-width: 1388px){
                top: 20px;
            }
        }
    }

    .services-popup-sidebar {
        /*position: fixed;
        top: 35vh;
        right: 10px;

        width: 400px;*/

        max-height: 80vh;
        overflow-y: auto;
    }

    .services-popup-content {
        max-height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .form__checkbox .form__checkbox__text {
            font-weight: bold;
        }
    }
}

.custom_voucher_selected_items {
    padding-top: 10px;
}

.services-popup-sidebar .custom_voucher_selected_items{
    padding-bottom: 10px;
}

.custom_voucher_selected_items .selected-service {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid @color-primary-light;
    padding-bottom: 7.5px;
    justify-content: space-between;
    flex-wrap: wrap;

    @media @min-xl{
        align-items: center;
    }

    &:last-child{
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.custom_voucher_selected_items .selected-service .form__checkbox__text {
    float: none;
    font-weight: bold;
    display: inline-block;
    //max-width: 94%;
    overflow: hidden;
    max-height: auto;
    height: auto;
    line-height: 1.3;
    margin-right: auto;
    max-width: ~"calc(100% - 15px)";
}

.custom_voucher_selected_items .selected-service .form__checkbox__close {
    font-size: 1.5rem;
}

.custom_voucher_selected_items .selected-service .form__checkbox__close::before {
    .icon-pseudo(@icon-cross);
}

.popup__cross {
    z-index: 9999;
}

.voucher-info-summary {
    //font-weight: bold;
    font-size: 1.15rem;
}

.voucher-info-summary .total-price-cont{
    font-size: @fs2;
    font-weight: bold;
}

.selected-service .form__checkbox__close {
    display: inline-block; 
    height: 20px; 
    line-height: 22px; 
    vertical-align: middle;
}

.selected-service .form__checkbox__text {
    width: 70%; 
    max-width: 70%; 
    height: 30px; 
    max-height: 30px; 

    line-height: 22px; 
    vertical-align: middle;
}

.selected-service .service-count {
    width: 35px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle; 
    max-height: 40px;
    text-align: center;
    outline: 0;
}

.selected-service .service-count-cont{
    width: 100%;
    padding-left: @x2*2;
    margin-top: 5px;

    @media @min-xl{
        width: auto;
        padding-left: @x2*2;
        margin-left: @x1;
        margin-top: 0;
    }
    
}

.selected-service .service-count-plus-minus {
    display: inline-block; 
    margin-top: 0px; 
    height: 40px;
    line-height: 40px;
    vertical-align: middle; 
    max-height: 40px;
    position: relative;
    margin-bottom: 0;
}

.selected-service .service-count-plus-minus .plus {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-left: 4px; 
    margin-right: 0;
}

.selected-service .service-count-plus-minus .minus {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    left: -85px;
    top:0;
}


.popup__cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.popup__cont.active {
  visibility: visible;
  opacity: 1;
}
.popup__cont.active .popup {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.popup__cont .popup {
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  transform: scale(0);
}