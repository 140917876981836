@header_height: 64px;
@menu_width: 333px;
@menu_width_mobile: 320px;

.header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: @header_height;
	background-color: @gray-lighter;

	h1{
		font-size: @fs2;
		text-transform: uppercase;
		margin-left: @x2;
		margin-right: @x1;

		@media @min-lg{
			margin-left: @x3*2;
		}
	}

	&__logo{
		max-width: 88px;
	}

	&__left{
		display: flex;
		align-items: center;
	}

	&__right{
		display: flex;
		align-items: center;
	}

	&__user{

		margin: 0 @x2 0 @x2;
		line-height: 1.3;

		a{
			text-decoration: none;
			color: @color;
		}

		@media @min-lg{
			margin: 0 @x2*2 0 @x2;
		}

		span{
			color: @gray-dark;
			font-size: @fs05;
		}

		&__logout{
			height: @header_height;
			line-height: @header_height;
			background-color: @gray-light;
			padding: 0 @x2;
			font-size: @fs05;
			color: @gray-darker;
			text-transform: uppercase;
			.strong;
			min-width: 150px;

			@media @min-lg{
				padding: 0 @x2;
			}

			@media @min-xl{
				padding: 0 @x3;
			}

			.icon{
				font-size: @fs2;
			}

			&:hover{
				text-decoration: none;
				color: @gray-darker;
			}
		}
	}

	&__menu{
		width: @menu_width_mobile;
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		background-color: @white;
		z-index: 99;
		transform: translateX(-@menu_width_mobile);
		transition: .5s all;
		box-shadow: 3px 0 30px fade(@black, 25%);

		@media @min-md{
			width: @menu_width;
			transform: translateX(-@menu_width);
		}

		&.show{
			transform: translateX(0);
		}

		&__inner{
			padding: @x3 @x3 0 @x3;

			.btn--block{
				max-width: 75%;
			}
		}

		&__nav{
			margin: @x3 0;
			display: block;

			li{
				margin: @x3 0;

				a{
					.strong;
					font-size: @fs2;
					color: @color;
					text-decoration: none;
					padding: @x1 0;
				}

				&.active{
					a{
						color: @color-primary;
					}
				}
			}
		}

		&__user{
			border-top: 1px solid @gray-light;
			padding: @x1 0;
			line-height: 1.3;

			a{
				text-decoration: none;
				color: @color;
			}

			span{
				color: @gray-dark;
				font-size: @fs05;
			}
		}

		&__logout{
			width: 100%;
			padding: 0 @x3;
			background-color: @gray-lighter;
			display: block;
			height: @header_height;
			line-height: @header_height;
			color: @gray-darker;
			text-transform: uppercase;
			font-size: @fs05;
			.strong;

			&:hover{
				color: @gray-darker;
				text-decoration: none;
			}

			i{
				font-size: @fs2;
			}
		}

		&__close{
			background-color: @color-primary;
			color: @white;
			height: @header_height;
			line-height: @header_height;
			font-size: @fs1;
			.strong;
			text-transform: uppercase;
			padding: 0 @x3;
			display: flex;
			align-items: center;
			width: 100%;

			&:hover{
				color: @white;
				text-decoration: none;
			}

			.icon{
				font-size: 1.384rem;
				margin-right: @x1;
				padding-right: 0;
			}
		}

		&__link{
			background-color: @color-primary;
			color: @white;
			height: @header_height;
			line-height: @header_height;
			font-size: @fs05;
			.strong;
			text-transform: uppercase;
			padding: 0 @x2;
			display: flex;
			align-items: center;
			margin-right: @x1;

			@media @min-md{
				padding: 0 @x3;
			}

			&:hover{
				text-decoration: none;
				color: @white;
			}

			.icon_menu{
				display: inline-block;
				height: 22px;
				width: 24px;
				position: relative;
				margin-right: @x05;

				span{
					content: "";
					width: 100%;
					position: absolute;
					height: 2px;
					background-color: @white;
					top: 0;
					bottom: 0;
					margin: auto;
				}

				&:before{
					content: "";
					width: 100%;
					position: absolute;
					height: 2px;
					background-color: @white;
					top: 0;
				}

				&:after{
					content: "";
					width: 100%;
					position: absolute;
					height: 2px;
					background-color: @white;
					bottom: 0;
				}
			}
		}
	}
}

.menu_overlay{
	z-index: 98;
	visibility: hidden;
	opacity: 0;
	position: fixed;
	width: 100%;
	height: 100%;

	&.show{
		visibility: visible;
		opacity: 1;
	}
}