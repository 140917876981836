// Nastaveni fontu
// -----------------------------------------------------------------------------

// Defaultni font: pouzit na <body>
@fontfamily-default:        ~"'Roboto', sans-serif";
@base-font-size-mobile:    13px;    // určuje, kolik je 1rem
@base-font-size-desktop:   13px;    // určuje, kolik je 1rem
@base-line-height:         1.6rem;

// Zde nastav konkretni cisla rezu nacitaneho pisma
.light {
    font-weight: 300;
}

//font-weight: normal;
.normal {
    font-weight: 400;
}

// roboto medium
.medium {
    font-weight: 600;
}

//font-weight: bold;
.strong {
    font-weight: 700;
}

// roboto black
.stronger {
    font-weight: 900;
}


// uppercase 
.uppercase {
    text-transform: uppercase;
}


// Html elements - default CSS
// -----------------------------------------------------------------------------
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

html {
    // Font sizes
    font-size: @base-font-size-mobile;

    @media @min-xl {
        font-size: @base-font-size-desktop;
    }
    
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, body {
    overflow-x: hidden;
}

body {
    font-family: @fontfamily-default;
    .normal;
    font-size: inherit;
    color: @color;
    line-height: @base-line-height;
    background: #fff;
}

p, ul, li, ol, i, pre {
    font-size: @fs1;
}

p {
    margin-top: @x1;
    margin-bottom: @x1;
}

img {
    vertical-align: middle;
}

b, strong {
    .strong;
}

// Highlight selected text - zvyrazneni textu vybraneho mysi
::-moz-selection {
    background-color: @black; 
    color: @white;
}
::-webkit-selection {
    background-color: @black; 
    color: @white;
}
::selection {
    background-color: @black; 
    color: @white;
}

.small {
    font-size: @fs05;
    @koef: replace(~"@{base-line-height}", "rem", "");
    line-height: calc(~"@{koef} * @{fs05}");
}

.large {
    font-size: @fs2;
}

// Odkazy
// -----------------------------------------------------------------------------

@color-a:           @color-primary;     // barva odkazu
@color-a-hover:     @color-primary;   // barva odkazu ve stavu hover, active, focus

a, .a {
    line-height: inherit;
    color: @color-a;
    text-decoration: none;
    cursor: pointer; 
    outline: none;
    .transition;

    &:hover, &:active, &:focus {
        color: @color-a-hover; 
        text-decoration:  underline;
    }
    
    // WebKit-style focus
    &:focus {
        // Default
        outline: thin dotted;
        // WebKit
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

a[href^="tel:"] {
    @media @min-xl {
        //.a--reset;
    }
}

.a--reset {
    color: @color; 
    text-decoration: none;
    
    &:hover, &:active, &:focus {
        color: @color; text-decoration: none;
    }

    cursor: default;
    pointer-events: none;
}

.a--white {
    color: @white;
    text-decoration: underline;

    &:hover, &:active, &:focus {
        color: @white;
    }
}


// Oddělovací čára
// -----------------------------------------------------------------------------

hr, .hr {
    margin-top: @x1;
    margin-bottom: @x1;
    border-top: 1px solid @gray-light;
}

// Font-size škála
// -----------------------------------------------------------------------------

// Promenne pro velikost
// Vizuální náhled zde: https://type-scale.com/
@fs0:    0;
@fs05:   0.923rem;
@fs1:    1rem;
@fs2:    1.230rem;
@fs3:    1.923rem;
@fs4:    2.769rem;
@fs5:    2.769rem;

// Názvy velikostí
@font-sizes:
    fs0,
    fs05,
    fs1,
    fs2,
    fs3,
    fs4,
    fs5;

.make-fs-classes-info(@i: length(@font-sizes)) when (@i > 0) {
    .make-fs-classes-info(@i - 1);
    
    @size: extract(@font-sizes, @i); 
    @val: @@size;
    // Hodnota v px
    .col-@{size}-info-rem {
        &:after {
            content: "@{val}";
        }
    }

    @px: @val * @base-font-size-desktop;
    @px_form:  replace(~"@{px}", "rem", "px");
    // Hodnota v px
    .col-@{size}-info-px {
        &:after {
            content: "@{px_form}";
        }
    }
}

.make-fs-classes-info();

// Headings
// -----------------------------------------------------------------------------

@heading-font-sizes-margins:
/* Arguments: 
 * @1 ... nazev elementu
 * @2 ... font-size
 * @3 ... margin-top (pouze pokud následuje za elementem nebo má třídu .heading--margin)
 * @4 ... margin-bottom;
 * @5 ... @max-sm:  font-size
 * @6 ... @max-sm: margin-top (pouze pokud následuje za elementem nebo má třídu .heading--margin)
 * @7 ... @max-sm: margin-bottom;
 */
    h1 @fs4     1rem 1rem       1.8rem 1rem 1rem,
    h2 @fs3     1rem 1rem       1.6rem 1rem 1rem,
    h3 @fs2     1rem 1rem       1.4rem 1rem 1rem,
    h4 @fs2     1rem 1rem       1.2rem 1rem 1rem,
    h5 @fs2     1rem 1rem       1.2rem 1rem 1rem,
    h6 @fs2     1rem 1rem       1.2rem 1rem 1rem;

// Vyska radku - nezadavat v REM, ale v relativnich jednotkach o zakladu 1
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.1;
    .strong;
}

// Helper mixins
.setHeadingFont(@args ...) {
    @name: extract(@args, 1);

    @{name}, .@{name} {
        font-size: extract(@args, 2);
        margin-top: 0;
        margin-bottom: extract(@args, 4);
    }
    
    * + @{name}, * + .@{name}, @{name}.heading--margin, .@{name}.heading--margin {
        margin-top: extract(@args, 3); 
    }

    @media @max-sm {
        @{name}, .@{name} {
            font-size: extract(@args, 5);
            margin-top: 0;
            margin-bottom: extract(@args, 7);
        }
        * + @{name}, * + .@{name}, .@{name}.heading--margin, .@{name}.heading--margin {
            margin-top: extract(@args, 6);
        }
    }
}

.setHeadingFonts(@i: 1) when (@i <= length(@heading-font-sizes-margins)) and (length(extract(@heading-font-sizes-margins, 1)) > 1) {
    .setHeadingFont(extract(@heading-font-sizes-margins, @i));

    .setHeadingFonts(@i + 1);
}
.setHeadingFonts() when (length(extract(@heading-font-sizes-margins, 1)) = 1) {
    .setHeadingFont(@heading-font-sizes-margins, @i);
}

.setHeadingFonts();


// Tabulka
// -----------------------------------------------------------------------------

.table {
    display: table;
    border-collapse: collapse;
    border-spacing: 0;

    .tr       { display: table-row }
    .thead    { display: table-header-group }
    .tbody    { display: table-row-group }
    .tfoot    { display: table-footer-group }
    .col      { display: table-column }
    .colgroup { display: table-column-group }
    .td, .th  { display: table-cell }
    .caption  { display: table-caption }

    width: 100%; 
    max-width: 100%;

    tr, .tr {
        height: @base-line-height * 1.5;
        color:  @color;

        &:nth-child(odd) {
            //background-color: @gray-lighter;
        }
        &:nth-child(even) {
            //background-color: @white;
        }
    }

    th, .th {
        height: @base-line-height * 2;
        border: 0 !important;
        background-color: @white;
        border: 1px solid @white;
        color: @black;
        .strong;
        
        @media @min-md {
            font-size: @fs2;
        }
    }

    td, th,
    .td, .th {
        padding: @x1;
        vertical-align: middle;
        border: 0 !important;
        //border-bottom: 1px solid @gray-light;
    }

    // https://css-tricks.com/responsive-data-tables/
    &--responsive {

        &__th {
            .strong;
        }

        @media @max-md {

            &, thead, tbody, th, td, tr { 
                display: block; 
            }

            tr, .tr {
                height: auto;
            }

            // Hide table headers
            thead tr,
            th, .th,
            & &__th {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            tr, .tr { 
                //border-bottom: 1px solid @color-secondary;

                &:nth-child(odd) {
                    background-color: @white;
                }
                &:nth-child(even) {
                    background-color: @white;
                }
            }
        
            td { 
                // Behave  like a "row"
                border: none;
                position: relative;
                // padding-left: 50%; 
                text-align: right;

                &:before { 
                    // Zobrazuje data-atribut na <td> a dvojtečku za ním
                    content: attr(data-responsive) ":";
                    display: block;
                    width: 100%;
                    text-align: left;
                    .strong;
                }
            }
        }
    }
}


//FIX pro načítání fontů, aby neproblikávaly
html:after{
    opacity: 0;
    //.transition;
    visibility: hidden;
    content: "";
    background-color: @white;
    z-index: 999999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

html.wf-loading:after{
    
    //opacity: 1;
    //visibility: visible;
}


//hlavní heading většinou h2
.main_heading{
    color: @color-primary;
    text-align: center;
    .strong;
    font-size: 2.15rem;
    margin: @x3 0;
}