// Styleguide: Seznamy
// =============================================================================

// Nastaveni
// -----------------------------------------------------------------------------

//  .ul
@list-icon:             @icon-list;
@list-icon-size:        1rem;
@list-icon-color:       @color-primary;

@list-indent:           calc(~"@{list-icon-size} + 15px");
@list-indent-large:     calc(~"@{large-icon-size} + 20px");

// ol
@list-number-size:      1rem;
@list-number-color:     @color-primary;

// Tridy pro jednotlive druhy seznamu
// -----------------------------------------------------------------------------

// Zakladni seznam bez tridy
ul {
    margin: 0;
    padding: 0;

    li {
        padding: 0;
        list-style: none;
    }
}

// Stylovany seznam
.ul, .ul--myicon, ol {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        margin-bottom: 1rem;
        line-height: 1;

        &::before {
            position: absolute; 
            top: 0; 
            left: 0;
        }
    }
}

.ul {
    li {
        padding-left: calc(~"@{list-icon-size} + 3px");

        &::before {
            .icon-pseudo(@list-icon, @list-icon-size);
            color: @list-icon-color;
        }
    }
}

.ul--myicon {
    li {
        &::before {display: none;}

        .icon {
            color: @list-icon-color;
        }
    }
}

.ul--indent {
    li {
        padding-left: @list-indent;

        .icon {
            .absolute;
            top: 0; 
            left: 0;
        }
    }
}

.ul--indent--large {
    li {
        padding-left: @list-indent-large;
    }
}

.ol, ol{
    li{
        padding-left: calc(~"@{list-number-size} + 10px");
        counter-increment: step-counter;

        &::before {
            content: counter(step-counter)'.';
            font-size: @list-number-size;
            color: @list-number-color;
            border: none;
            font-family: @fontfamily-default;
        }
    }
}

.ul--vertical {
    display: block; 
    .clearfix;

    li {
        display: inline-block; 
        float: left;
        padding-left: @x1;
        padding-right: @x1;
        border-right: 1px solid @gray-light;
        line-height: 1.5rem;

        &:first-child {
            padding-left: 0;
        }
        &:last-child  {
            padding-right: 0;
            border: none;
        }
    }
}


