@store_height: 188px;

.stores{

	.store{
		display: block;
		width: 100%;
		height: @store_height;
		margin-bottom: @x2;
		background-size: cover;
		border-radius: 3px;
		text-decoration: none;
		position: relative;
		background-position: 50%;

		&:before{
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			border: 5px solid transparent;
			border-radius: 3px;
			.transition;
		}

		&__label{

			.strong;
			color: @white;
			text-decoration: none;
			bottom: @x2;
			left: @x1;
			position: absolute;
			display: flex;
    		align-items: center;
    		.transition;

			&:before{
				content: @icon-check;
				.icon;
				width: @checkbox-height;
        		height: @checkbox-height;
        		background-color: @white;
        		border-radius: 2px;
        		color: transparent;
        		padding: 0;
        		margin-right: @x05;
        		.transition;
        		display: flex;
			    align-items: center;
			    justify-content: center;
			}
		}

		&.selected{

			&:before{
				border-color: @color-primary;
			}

			.store__label{
				&:before{
					background-color: @color-primary;
					color: @white;
				}
			}
		}
	}
}