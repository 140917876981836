// Styleguide: Grid
// =============================================================================

// Pocet sloupcu (bootstrap default: 12)
@grid-columns:          12;

// Sirka mezery (kazdy sloupec bude mit padding nastaven na polovinu teto hodnoty)
// Z teto hodnoty muze take vychazet komponenta Box
@grid-gutter-width:     30px;

// Media queries breakpoints
// -----------------------------------------------------------------------------

// Mobilni telefon
@screen-xs:                  0px;
@screen-xs-min:              @screen-xs;
@screen-phone:               @screen-xs-min;

// Mobilni telefon na sirku
@screen-sm:                  576px;
@screen-sm-min:              @screen-sm;
@screen-tablet:              @screen-sm-min;

// Tablet
@screen-md:                  768px;
@screen-md-min:              @screen-md;
@screen-desktop:             @screen-md-min;

// Tablet na sirku
@screen-lg:                  992px;
@screen-lg-min:              @screen-lg;
@screen-lg-desktop:          @screen-lg-min;

// Desktop
@screen-xl:                  1230px;
@screen-xl-min:              @screen-xl;
@screen-xl-desktop:          @screen-xl;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);
@screen-lg-max:              (@screen-xl-min - 1);

/* Variable for long string */
@min-xs: ~"(min-width: @{screen-xs-min})";
@min-sm: ~"(min-width: @{screen-sm-min})";
@min-md: ~"(min-width: @{screen-md-min})";
@min-lg: ~"(min-width: @{screen-lg-min})";
@min-xl: ~"(min-width: @{screen-xl-min})";

@max-xs: ~"(max-width: @{screen-xs-max})";
@max-sm: ~"(max-width: @{screen-sm-max})";
@max-md: ~"(max-width: @{screen-md-max})";
@max-lg: ~"(max-width: @{screen-lg-max})";

.container-fluid{
	padding-left: @x3;
	padding-right: @x3;
}