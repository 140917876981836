
/* =============== STYLES =============== */

/* Basic */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	
}

body {
	padding: 0;
	padding-bottom: @x3;
}

a {
	cursor: pointer;
}

pre{
	margin: @x1 0;
}

.flex_row{
	display: flex;
	justify-content: space-between;

	.select-box{
		min-width: 250px;
	}
}

.alert-success{
	border-color: @color-secondary;
	background-color: fade(@color-secondary, 10);
	color: @color-secondary;
}

.alert-danger{
	border-color: @color-error;
	background-color: fade(@color-error, 10);
	color: @color-error;
}