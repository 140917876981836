// Styleguide: Boxy
// =============================================================================

// NASTAVENI
// -----------------------------------------------------------------------------

//radius pro boxy
@radius-box:     5px;

// @grid-gutter-width definován v grid.less
@space-width: @grid-gutter-width / 2;

// Promenne pro velikost
@x0:    0;
@x05:   @space-width * 0.5;
@x1:    @space-width;
@x2:    @space-width * 1.5;
@x3:    @space-width * 2;
@x4:    @space-width * 3;

// nazvy velikosti
@space-sizes:
    x0,
    x05,
    x1,
    x2,
    x3,
    x4;
    
// Helper mixins
// -----------------------------------------------------------------------------
.make-space-classes-all(@classname; @i: length(@space-sizes)) when (@i > 0) {
    .make-space-classes-all(@classname; @i - 1);
    
    @size: extract(@space-sizes, @i); 
    .@{classname}--@{size} {
        @{classname}: @@size;  
    }
}

.make-space-classes-v(@classname; @i: length(@space-sizes)) when (@i > 0) {
    .make-space-classes-v(@classname; @i - 1);
    
    @size: extract(@space-sizes, @i); 
    .@{classname}--v@{size} {
        @{classname}-top: @@size;  
        @{classname}-bottom: @@size;  
    }
}

.make-space-classes-h(@classname; @i: length(@space-sizes)) when (@i > 0) {
    .make-space-classes-h(@classname; @i - 1);
    
    @size: extract(@space-sizes, @i); 
    .@{classname}--h@{size} {
        @{classname}-left: @@size;  
        @{classname}-right: @@size;  
    }
}

.make-boxmodel-classes-info(@i: length(@space-sizes)) when (@i > 0) {
    .make-boxmodel-classes-info(@i - 1);
    
    @size: extract(@space-sizes, @i); 
    @val: @@size;
    // Hodnota v px
    .col-@{size}-info-px {
        &:after {
            content: "@{val}";
        }
    }
    // Koeficient - vypocitava se
    @koef_px: @val / @space-width;
    @koef: replace(~"@{koef_px}", "px", "");
    .col-@{size}-info-koef {
        &:after {
            content: "@{koef}";
        }
    }
}

// Vytvor tridy
// -----------------------------------------------------------------------------

// vytvori tridy padding--x0 apod se zadanou CSS vlastnosti pro vsechny velikosti
.make-space-classes-all(padding);
.make-space-classes-v(padding);
.make-space-classes-h(padding);

.make-space-classes-all(margin);
.make-space-classes-v(margin);
.make-space-classes-h(margin);

// Styleguide - info tridy
.make-boxmodel-classes-info();

// Clear bloky
// -----------------------------------------------------------------------------
.clear {
    width: 100%;
    height: 0px;
    display: block;
    margin: 0;
    padding: 0;
    clear: both;
    .clearfix; 
}

// Vytvori bloky clear-10 apod.
.generate-vertical-space(@n, @i: 0) when (@i =< @n) {
    @space: @i * 10;
    @height: @i * 10px;

    .clear-@{space} {
        .size(100%, @height);
        .clearfix; 
        clear: both;
        margin: 0;
        padding: 0;
    }

    .generate-vertical-space(@n, (@i + 1));
}

.generate-vertical-space(10);

// Vytvori bloky clear-x1 apod
.generate-vertical-space-boxnames(@i: length(@space-sizes)) when (@i > 0) {
    @size: extract(@space-sizes, @i); 
    @val: @@size;

    .clear-@{size} {
        .size(100%, @val);
        .clearfix; 
        clear: both;
        margin: 0;
        padding: 0;
    }

    .generate-vertical-space-boxnames(@i - 1);
}

.generate-vertical-space-boxnames();