.dataTables_length {
	display: none;
}

.dataTables_info {
	display: none;
}

.dataTables_paginate.paging_simple_numbers .row > div:last-child {
	text-align: right;
}

.dataTable {
	width: 100% !important;
}

.dataTables_wrapper > .row .col-sm-6:first-child {
	display: none;
}

.dataTables_wrapper{
	.dataTables_filter{

		position: relative;

        &:after{
            .icon;
            content: @icon-search;
            position: absolute;
            right: 0;
            z-index: 2;
            font-size: @fs2;
            bottom: 10px;
            margin: auto;
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: @gray;
            right: @x1;
        }

		label{
			.label;

		}

		input{
			display: block;
			width: 100%;
			.transition;
	        position: relative;
	        color: @input-color;
	        font-size: @fs05;
	        width: 100%;
	        height: @input-height;
	        line-height: 1;
	        padding-left: @x1;
	        padding-right: @x1;
	        background: @input-background;
	        border-radius: @input-border-radius;
	        border: @input-border;
	        // box-shadow: @input-box-shadow;
	        vertical-align: middle;
	        z-index: 2;	

	        &::-webkit-input-placeholder {
	            transition: color .2s linear;
	            color: @gray;
	        }

	        &:focus {
	            border-color: @input-focus-color;
	            outline: none;
	            box-shadow: @input-box-shadow;
	        }

	        &:disabled{
	            opacity: 0.5;
	            background-color: @gray-lighter;
	        }

	        &:required{
	            border-color: @black;
	        }
		}
	}

	.table_listing{

		@media @max-sm{
			overflow-y: scroll;
			width: 100%;
		    margin-bottom: 15px;
		    overflow-y: hidden;
		}

		thead{
			th{
				padding: @x1 @x1;
				padding-left: @x4;
				outline: 0;

				font-weight: bold;

				color: @gray-darker;

				&.sorting, &.sorting_asc, &.sorting_desc{
					cursor: pointer;
					position: relative;

					&:after{
						content: @icon-filter;
						.icon;
						position: absolute;
						left: @x1;
						font-size: @fs2;
						transform: rotate(0deg);
						padding: 0;
					}
				}

				&.sorting_disabled{
					padding-left: @x1;
				}

				&.sorting_asc, &.sorting_desc{
					color: @color;
				}

				&.sorting_asc{
					&:after{
						transform: rotate(180deg);
					}
				}
			}
		}

		tbody{
			td{
				padding: @x2 @x1;
				border-bottom: 1px solid @gray-light;
				color: @gray-darker;

				&.sorting_1{
					color: @color;
				}

				mark{
					background-color: @mark;
				}

				a{
					text-transform: uppercase;
					color: @color-primary;
					text-decoration: none;
					.strong;
					display: inline-block;
					vertical-align: middle;
					display: flex;

					.icon{
						font-size: @fs2;
					}

					&.has-tooltip{
						color: @gray-darker;
						position: relative;
						&:after{
							position: absolute;
							content: "";
							width: 100%;
							left: 0;
							bottom: 1px;
							border-bottom: 1px dashed @color;
						}

						&.has-link{
							color: @color-primary;
							&:after{
								border-color: @color-primary;
							}
						}
					}
				}

				.like-link{
					.uppercase;
					.strong;
				}
			}
		}

		.akce{
			text-align: center;

			@media @min-lg{
				text-align: left;
			}

			a {
				display: inline-block;
				margin-right: 10px;
				white-space: nowrap;
				margin-bottom: 5px;
				margin-top: 5px;
			}

			.alone_icon{
				color: @color;

				&:hover{
					color: @color;
				}
			}
		}
	}
}

.pagination{
	li{
		a{
			color: @black;
			.strong;
			background-color: transparent;
			border: 0;
			outline: 0;
			border: 1px solid transparent;
			margin-left: @x1;
			padding-top: ~"calc(@{x05} - 1px)";
			padding-bottom: ~"calc(@{x05} + 2px)";

			&:hover, &:focus{
				border: 1px solid @color-primary;
				background-color: transparent;
			}
		}

		span{
			color: @black;
			.strong;
			background-color: transparent;
			border: 0;
			margin-left: @x1;
		}

		&.active{
			a{
				color: @color-primary;
				border: 0;
				border-bottom: 3px solid @color-primary;
				background-color: transparent;
				padding-top: @x05;
				padding-bottom: @x05;

				&:hover, &:focus{
					background: transparent;
					color: @color-primary;;
					border-color: @color-primary;
				}
			}
			
		}
	}
}

.select-pagination {
	margin: 20px 10px;
}

.datatable_add_link{
	margin-top: @x1;
	margin-bottom: @x1;

	@media @min-lg{
		margin-top: @x2;
		margin-bottom: 0;
	}
}

.table-responsive{
	border: 0;
	overflow-x: inherit;
}

th.multiple-operations {
    min-width: 110px !important;

	padding-left: 15px !important;	
}

th.multiple-operations label,
th.multiple-operations label input {
	cursor: pointer;
}

th.multiple-operations::after {
	display: none !important;
}

.multiple-operations-list {
	padding-top: 15px;
}

.multiple-operations-list select {
	margin-left: 10px;
	margin-right: 10px;
}

.tooltip .tooltip-inner{
	max-width: 320px !important;
}

.datatable-export {
  margin-left: 20px;
}

.btn-resend.disabled {
	pointer-events: none !important;
	color: #87888a !important;
}
