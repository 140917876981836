
@icon-arrow_small: "\E001";

@icon-check: "\E002";

@icon-close_small: "\E003";

@icon-cross: "\E004";

@icon-date: "\E005";

@icon-eye: "\E006";

@icon-file: "\E007";

@icon-filter: "\E008";

@icon-list: "\E009";

@icon-logout: "\E00A";

@icon-mail: "\E00B";

@icon-pen: "\E00C";

@icon-search: "\E00D";

@icon-select_arrow: "\E00E";

@icon-text: "\E00F";

@icon-thumb: "\E010";

@icon-trash: "\E011";


.icon-arrow_small::before {
    content: "\E001";
}

.icon-check::before {
    content: "\E002";
}

.icon-close_small::before {
    content: "\E003";
}

.icon-cross::before {
    content: "\E004";
}

.icon-date::before {
    content: "\E005";
}

.icon-eye::before {
    content: "\E006";
}

.icon-file::before {
    content: "\E007";
}

.icon-filter::before {
    content: "\E008";
}

.icon-list::before {
    content: "\E009";
}

.icon-logout::before {
    content: "\E00A";
}

.icon-mail::before {
    content: "\E00B";
}

.icon-pen::before {
    content: "\E00C";
}

.icon-search::before {
    content: "\E00D";
}

.icon-select_arrow::before {
    content: "\E00E";
}

.icon-text::before {
    content: "\E00F";
}

.icon-thumb::before {
    content: "\E010";
}

.icon-trash::before {
    content: "\E011";
}