// Styleguide: Tlačítka
// =============================================================================

// Nastavení
// -----------------------------------------------------------------------------

// Border-radius pro vsechny typy buttonu
@btn-radius:         2px;
@btn-radius-small:   2px;
@btn-radius-large:   2px;

@btn-height-small:   1.3rem;
@btn-height:         1.9rem;
@btn-height-large:   2.5rem;

// Zde se nastavují barvy pro všechna tlačítka
// Popis parametrů:

//  název třídy
//      border-color                background-color            text-color

//     1.řádek = default stav
//     2.řádek = hover, focus
//     3.řádek = active

@buttons:
default
@gray-darker        @white                 @gray-darker
@gray-darker        @gray-darker        @white
@gray-darker        @gray-darker        @white,
primary
@color-primary     @color-primary         @white
@color-primary     @color-primary         @white
@color-primary     @color-primary         @white,
secondary
@color-secondary         @color-secondary             @white
@color-secondary         @color-secondary             @white
@color-secondary         @color-secondary             @white,
primary_bordered
@color-primary         @white     @color-primary
@color-primary         @color-primary             @white
@color-primary         @color-primary             @white,
primary_bordered_fade
@color-primary         fade(@color-primary, 10%)     @color-primary
@color-primary         @color-primary             @white
@color-primary         @color-primary             @white,
secondary_bordered
@color-secondary         @white     @color-secondary
@color-secondary         @color-secondary              @white
@color-secondary         @color-secondary              @white,
link
transparent         transparent              @gray-darker
transparent         transparent              @gray-darker
transparent         transparent              @gray-darker;


// Základní třída
// -----------------------------------------------------------------------------
.btn {
    display: inline-block;
    padding-left: @x2;
    padding-right: @x2;
    box-sizing: border-box;

    background-image: none; // reset
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: @btn-radius;
    outline: none;
    text-transform: uppercase;

    text-align: center;
    font-size: @fs1;
    .strong;
    line-height: @btn-height;

    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    .transition;
    outline: 0;

    &:hover, &:active, &:focus {
        text-decoration: none;
        outline: 0;
    }
}

// Helper mixins
// -----------------------------------------------------------------------------
.makeButton(@args ...) {
    border-color: extract(@args, 2);
    background-color: extract(@args, 3);
    color: extract(@args, 4);
    text-decoration: none;

    &:hover, &:active, &:focus {
        text-decoration: none;
    }

    &:hover, &:focus {
        border-color: extract(@args, 5);
        background-color: extract(@args, 6);
        color: extract(@args, 7);
    }

    &:active {
        border-color: extract(@args, 8);
        background-color: extract(@args, 9);
        color: extract(@args, 10);
    }

    a, input[type=submit] {
        color: extract(@args, 4);
        text-decoration: none;

        &:hover, &:active, &:focus {
            text-decoration: none;
        }

        &:hover, &:focus {
            color: extract(@args, 7);
        }

        &:active {
            color: extract(@args, 10);
        }
    }
}

.generateButtons(@i:1) when(@i <= length(@buttons)) {
    @name:         extract(extract(@buttons, @i),1);

    /* bnt-name je fallback na komponenty */
    .btn--@{name}
    /*, .btn-@{name}*/ {
        .makeButton(extract(@buttons, @i));
    }

    .generateButtons((@i + 1));
}
.generateButtons();

// Třídy modifikátorů
// -----------------------------------------------------------------------------

.btn--small {
    border-radius: @btn-radius-small;
    //.small;
    font-size: @fs05;
    line-height: @btn-height-small;
}

.btn--normal {
    border-radius: @btn-radius !important;
    font-size: @fs2 !important;
    line-height: @btn-height !important;
}

.btn--large {
    border-radius: @btn-radius-large;
    font-size: @fs2;
    //.large;
    line-height: @btn-height-large;
}

.btn--reset {
    .a;
    .normal;

    .inline-block;
    margin: 0;
    padding: 0;
    line-height: inherit;

    box-shadow: none;

    text-align: left;

    vertical-align: top;
    white-space: normal;

    &, &:hover, &:active, &:focus {
        border: none; box-shadow: none;
        background-color: transparent;
        outline: none;
    }
}

.btn--disabled,
.btn.disabled {
    cursor: default;
    pointer-events: none;

    //box-shadow: inset 0 0 9999px 9999px rgba(255,255,255,0.5);
    border-color: @gray-light;
    color: @gray;

    &.btn--primary, &.btn--secondary{
        background-color: @gray-light;
    }
}

.btn--block {
    width: 100%;
}

.btn--uppercase {
    .uppercase;
    .medium;
}

.btn--link .icon{
    font-size: @fs2;
}

.link--block{
    display: block;
    width: 100%;
    text-align: center;
}